html {
  // height: 100%;
  overflow-x: hidden;
}

body {
  // height: 100%;
  // overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}
// #root {
//   @include for-size(tabldesktopet) {
//     &::-webkit-scrollbar {
//       width: 0;
//       height: 0;
//     }
//   }
// }
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

.container {
  height: 100%;
  margin: 0 auto;
  padding: 0 20px 0 20px;

  @include for-size(mobile) {
    max-width: 768px;
    overflow: auto;
  }
  @include for-size(tablet) {
    padding: 0 45px 0 45px;

    width: 768px;
  }
  @include for-size(desktop) {
    padding: 0 50px 0 50px;
    width: 1280px;
  }
  @include for-size(fullDesktop) {
    width: 1440px;
  }
}
