@include for-size(desktop) {
    .menu-link {
        display: block;
        font-family: Montserrat !important;
        font-size: 15px !important;
        line-height: 18px !important;

        padding: 8px 0 8px 8px !important;
        min-width: 157px;
        align-items: center;
        justify-content: space-between !important;
    }
    .arrowDown {
        top: 10px;
        width: 14px;
        height: 16px;
    }
    .menu-item {
        height: 23px;
    }
    .menu-item-active {
        margin: 0 !important;
    }
    .dropdown-link {
        font-family: Montserrat;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        justify-content: flex-start;
        padding: 8px 0 8px 8px;

        background-color: transparent;
        border: none;
    }
}
