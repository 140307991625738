@include for-size(tablet) {
  .section-slider {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .wrapper-slider-desk {
    position: relative;
    z-index: 1;
  }
  .container-sec-text {
    margin-bottom: 60px;
    text-align: center;
  }
  .wrapper-card-slider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;

    column-gap: 15px;
  }
  .list-slider {
    position: relative;
  }
  .item-slider {
    opacity: 0;

    transform: translate(-300%);
    transition: all 250ms;

    &.active {
      opacity: 1;
      transform: translate(0);

      transition: all 250ms;
    }
  }

  .slide-text-wrapper {
    grid-row-start: 1;
    grid-row-end: 3;
    text-align: left;
  }
  .slide-img {
    display: block;

    grid-column: 1;
    grid-row: 1;
  }
  .slide-header-wrapper {
  }
  .slide-header {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 30px;
    line-height: 23px;

    text-transform: capitalize;

    color: $color-text-slider;

    margin-bottom: 12px;
  }
  .slide-paragraph-wrapper {
  }
  .slide-paragraph {
    font-family: Roboto;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;

    color: $color-text-popup-card;
  }
  .slide-btn {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    background: transparent;
    color: $standart-color-black;
    border: 1px solid $border-btn-slide;
    border-radius: 5px;

    padding: 10px 15px 10px 15px;

    height: 55px;
  }
  .general-text-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-family: Montserrat;
    font-weight: 700;
    font-size: 20px;
    line-height: 15px;

    color: $standart-color-black;
  }
  .subGeneral-text-btn {
    font-family: Montserrat;
    font-weight: normal;
    font-size: 11px;
    line-height: 8px;

    color: $border-btn-slide;

    margin-top: 5px;
  }
  .price-btn-slide {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;

    color: $standart-color-black;
  }
  .header-secOne {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 11px;

    letter-spacing: 0.885em;
    text-transform: uppercase;

    color: $color-head-secOne;

    margin-bottom: 19px;
  }
  .subHeader-secOne {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 45px;
    line-height: 34px;

    color: $standart-color-black;
  }
  .previous-btn {
    position: absolute;
    top: 30%;
    left: -40px;
    border: none;
    background: $bg-btn-nav-slide;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 9px 12px 9px;

    transform: rotate(180deg);
  }
  .next-btn {
    position: absolute;
    top: 30%;
    right: -40px;
    border: none;
    background: $bg-btn-nav-slide;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 9px 12px 9px;
  }
  .wrapper-img-slide {
    position: relative;
    display: inline-block;
  }
  .wrapper-value-slide {
    position: absolute;
    bottom: 21px;
    left: 21px;
    text-align: left;
  }

  .value-slide {
    display: flex;
    flex-direction: column;

    font-family: Roboto;
    font-weight: 700;
    font-size: 20px;
    line-height: 15px;

    color: $standart-color-white;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  .name-value-slide {
    font-family: Roboto;
    font-weight: 400;
    font-size: 15px;
    line-height: 11px;

    color: $color-text-value-slide;

    margin-top: 3px;
  }
  .arrowRight-slider {
    width: 12px;
    height: 21px;
  }
}
