@mixin for-size($size) {
  @if $size == mobile {
    @media screen and (max-width: 767px) {
      @content;
    }
  } @else if $size == tablet {
    @media screen and (min-width: 768px) and (max-width: 1279px) {
      @content;
    }
  } @else if $size == desktop {
    @media screen and (min-width: 1280px) {
      @content;
    }
  } @else if $size == fullDesktop {
    @media screen and (min-width: 1440px) {
      @content;
    }
  }
}
