@include for-size(desktop) {
  .wrapper-header {
    background-image: linear-gradient(
        to top,
        $bg-second-color,
        $bg-second-color
      ),
      url(../../../img/bg_desk.jpg);
    padding-top: 28px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    height: 100vh;
    & .container {
      position: relative;
    }
  }

  .headerDesk-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
