@include for-size(desktop) {
  .footer {
    padding: 18px 0 18px 0;
    background-color: $bg-footer;
    color: $standart-color-white;
  }
  .wrapper-info-footer {
    display: flex;
    justify-content: space-between;
  }

  .street-head {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 15px;
    line-height: 11px;

    margin-bottom: 8px;
  }
  .street-text {
    font-family: Open Sans;
    font-weight: 400;
    font-size: 15px;
    line-height: 11px;

    margin-bottom: 33px;
  }
  .copyright {
    font-family: Open Sans;
    font-weight: 400;
    font-size: 15px;
    line-height: 11px;

    color: $subColor-text-footer;
  }
  .wrapper-contacts-footer {
  }
  .head-contacts-footer {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 15px;
    line-height: 11px;

    margin-bottom: 8px;
  }
  .contacts-footer-list {
  }
  .link-contact-footer {
    font-family: Open Sans;
    font-weight: 400;
    font-size: 15px;
    line-height: 11px;

    color: $standart-color-white;
  }
  .contacts-footer-item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  .wrapper-logo-footer {
    position: relative;
    display: flex;
    align-items: center;

    padding-left: 165px;
    padding-right: 165px;

    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 108px;
      height: 1px;

      background-color: $subColor-text-footer;
      border-radius: 5px;
    }
    &::after {
      content: " ";
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      width: 108px;

      height: 1px;
      background-color: $subColor-text-footer;
      border-radius: 5px;
    }
  }
  .logo-footer {
    width: 50px;
    height: 55px;
  }
  .work-footer-list {
    position: relative;

    display: flex;
    align-items: center;
  }
  .work-footer-item {
    &:not(:last-child) {
      margin-right: 30px;
      &::before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 37px;
        height: 2px;
        background-color: $subColor-text-footer;
        border-radius: 5px;

        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
  .work-text-day {
    font-family: Open Sans;
    font-weight: 400;
    font-size: 15px;
    line-height: 11px;

    margin-bottom: 11px;
  }
  .work-text-hour {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 16px;
    line-height: 12px;
  }
}
