@include for-size(desktop) {
  .container-nav {
    position: absolute;
    left: 0;
    bottom: 28px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .nav-list-tab {
    display: flex;
  }
  .nav-item-tab {
    position: relative;
    background: $bg-link-nav-desk;
    // background: $bg-color-subMenu;
    border: 1px solid $standart-color-white;
    border-radius: 3px;
    &:hover {
      background: $bg-color-subMenu;

      transition: all 800ms;
    }
    &:focus {
      background: $bg-color-subMenu;

      transition: all 800ms;
    }

    &:nth-child(3) {
      .absolute-info-link-item {
        &::before {
          display: none;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        display: block;
      }
      .absolute-info-link-list {
        padding-top: 5px;
      }
    }
    &:nth-child(4) {
      .absolute-info-link-item {
        &::before {
          display: none;
        }
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
      .absolute-info-link-list {
        padding-top: 3px;
        padding-left: 3px;
      }
    }

    &:not(:last-child) {
      margin-right: 25px;
    }
    transition: all 800ms;

    &.active {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-top: none;
      background: $bg-color-subMenu;

      transition: all 800ms;
    }
    &.activeAbout {
      background: $bg-color-subMenu;

      transition: all 800ms;
    }
  }
  .link-item-nav {
  }
  .icon-nav {
    width: 22px;
    height: 22px;

    margin-right: 8px;
  }
  .general-link-text {
    display: flex;
    flex-direction: column;

    min-width: 105px;

    font-family: Montserrat;
    font-weight: 700;
    font-size: 18px;
    line-height: 14px;

    color: $standart-color-white;
  }
  .sub-text-link {
    margin-top: 5px;

    font-weight: 500;
    font-size: 16px;

    line-height: 12px;

    color: $subText-link-nav-desk;
  }
  .wrapper-content-link-menu {
    padding: 16px 76px 16px 25px;
    display: flex;
    align-items: center;
  }
  .absolute-info-link-list {
    position: absolute;
    bottom: 64px;
    left: -1px;
    width: 101%;
    padding: 22px 0 22px 25px;
    background: $bg-color-openMenu;
    border-top: 1px solid $standart-color-white;
    border-left: 1px solid $standart-color-white;
    border-right: 1px solid $standart-color-white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    opacity: 0;
    transition: all 800ms;
    pointer-events: none;
    &.active {
      opacity: 1;
      transition: all 800ms;
      pointer-events: painted;
    }
  }

  .absolute-info-link-item {
    position: relative;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 40px;

      &::before {
        content: " ";
        position: absolute;
        bottom: -25px;
        right: 97px;
        display: block;
        width: 76px;
        height: 2px;
        background-color: $subText-link-nav-desk;
        border-radius: 9px;
      }
    }
  }

  .wrapper-menu-work {
  }
  .days-menu-work-desk {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 21px;
    line-height: 16px;
    margin-bottom: 8px;

    color: $subText-link-nav-desk;
  }
  .hour-menu-work-desk {
    font-family: Montserrat;
    font-weight: 900;
    font-size: 17px;
    line-height: 13px;

    white-space: nowrap;
    color: $standart-color-white;
  }
  .wrapper-menu-contacts-tab {
    display: inline;
    & .contact-menu-desk {
      &:not(:last-child) {
        margin-bottom: 11px;
      }
    }
  }
  .contact-menu-desk {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 10.7px;
    line-height: 7px;

    color: $standart-color-white;
  }
  .communications-menu {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 13px;

    color: $standart-color-white;
  }
  .name-menu-contact-desk {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 15px;
    line-height: 11px;

    color: $subText-link-nav-desk;

    margin-top: 8px;
  }
  .map-tab-img {
    width: 230px;
    height: 200px;
    border-radius: 6px 6px 0px 0px;
  }

  .workMF-iconTab {
    fill: $icon-color-menu;
    width: 50px;
    height: 50px;
    margin-right: 18px;
  }
  .workSat-iconTab {
    fill: $icon-color-menu;
    width: 50px;
    height: 50px;
    margin-right: 18px;
  }
  .contactsPhone-iconTab {
    fill: $icon-color-menu;
    transform: rotate(135deg);
    width: 16px;
    height: 16px;
    margin-bottom: 5px;
  }
  .contactsEmail-iconTab {
    fill: $icon-color-menu;
    width: 22px;
    height: 16.5px;
    margin-bottom: 10px;
  }
}
