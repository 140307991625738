@include for-size(desktop) {
  .section-aboutUs {
    background-image: linear-gradient(
        to top,
        $bg-second-color,
        $bg-second-color
      ),
      url(../../../img/AboutUsBg.jpg);
    //   padding-top: 22px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    height: 100vh;
  }
  .wrapper-text-aboutUs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .header-aboutUs {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 20px;
    line-height: 15px;

    letter-spacing: 0.885em;
    text-transform: uppercase;

    color: $standart-color-white;

    margin-bottom: 19px;
  }
  .subHeader-aboutUs {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 65px;
    line-height: 49px;

    color: $standart-color-white;
  }
}
