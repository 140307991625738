@include for-size(tablet) {
    .hero-text-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        text-align: center;
    }
    .hero-text-header {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 19px;
        line-height: 14px;

        letter-spacing: 0.885em;
        text-transform: uppercase;

        color: $standart-color-white;
        margin-bottom: 24px;
    }
    .hero-text-subHeader {
        font-family: Montserrat;
        font-weight: 900;
        font-size: 65px;
        line-height: 49px;

        text-transform: capitalize;

        color: $standart-color-white;
    }
}
