$standart-color-black: #000000;
$standart-color-white: #fff;

$bg-second-color: rgba(0, 0, 0, 0.3);
$color-text-menu: #f2f2f2;
$bg-color-menu: rgba(31, 42, 60, 0.97);
$bg-color-menu-item: rgba(18, 18, 24, 0.8);
$color-hr-menu: #202025;
$color-hors-menu: #0772bf;
$color-text-contacts-menu: #4e4e4e;
$icon-color-menu: #00e7d9;

$color-text-card: #08bfb8;
$color-border-text-card: rgba(19, 19, 19, 0.5);
$bg-card: #212121;
$bg-border-card: #575757;
$color-text-position: #848484;
$color-btn-info-postition: #ac9500;
$color-designation-position: #ffdc00;
$color-text-popup-card: #434343;
$border-popup-card: #434146;
$overflow-popup-card: rgba(0, 0, 0, 0.65);
$plus-popup: #4f4f4f;

$subText-link-nav-desk: #a1a1a1;
$bg-link-nav-desk: rgba(78, 78, 78, 0.25);

$color-text-slider: #001227;
$color-head-secOne: #737373;
$border-btn-slide: #797a7a;
$bg-btn-nav-slide: rgba(79, 84, 101, 0.32);
$color-text-value-slide: #09a9a3;

$bg-footer: #222222;
$subColor-text-footer: #bbbbbb;

$bg-color-openMenu: rgba(0, 0, 0, 0.32);
$bg-color-subMenu: rgba(0, 0, 0, 0.438);

// font-family: 'Montserrat', sans-serif;
// font-family: 'PT Sans', sans-serif;
// font-family: 'Roboto', sans-serif;
// font-family: 'Ubuntu', sans-serif;
// font-family: 'Yesteryear', cursive;
