@include for-size(tablet) {
    .menu-link {
        font-family: Montserrat !important;
        font-size: 8px !important;
        line-height: 10px !important;

        padding: 8px 0 8px 8px !important;
        min-width: 100px;
        align-items: center;
        justify-content: space-between !important;
    }
    .arrowDown {
        top: 13px;
    }
    .menu-item {
        height: 23px;
    }
    .menu-item-active {
        margin: 0 !important;
    }
    .dropdown-link {
        font-family: Montserrat;
        font-weight: 700;
        font-size: 8px;
        line-height: 10px;
        justify-content: flex-start;
        padding: 8px 0 8px 8px;
    }
}
